<template>
  <vx-card title="Invoice Exchange Edit">
    <div class="flex flex-col gap-5 mt-4">
      <!-- <vs-prompt
        @cancel="resetFinalDatas"
        @accept="updateData"
        @close="resetFinalDatas"
        :active.sync="isDialogShown"
      >
        <div class="con-exemple-prompt">
          <h5>Are you sure you want to update this invoice exchange?</h5>
        </div>
      </vs-prompt> -->

      <!-- <div class="flex flex-col gap-4">
        <h5>Invoices</h5>
        <div class="w-full">
          <vs-table :data="responseData">
            <template slot="thead">
              <vs-th> Invoice </vs-th>
              <vs-th> Gr Number </vs-th>
              <vs-th> Supplier Code </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="index" v-for="(invoice, index) in responseData">
                <vs-td :data="data[index].ID">
                  Code : {{ invoice.invoice_code }}
                  <br />
                  Faktur : {{ invoice.faktur_number }}
                  <br />
                  Date : {{ dateFormat(invoice.date) }}
                  <br />
                  Due Date : {{ dateFormat(invoice.due_date) }}
                  <br />
                  Total : {{ priceFormat(invoice.total) }}
                </vs-td>

                <vs-td>
                  <vs-input
                    v-model="invoiceModels[index].gr_number"
                    class="w-full"
                  />
                </vs-td>

                <vs-td>
                  <vs-input
                    v-model="invoiceModels[index].supplier_code"
                    class="w-full"
                  />
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div> -->
      <div class="flex flex-col gap-4">
        <vx-card>
          <div class="flex flex-col gap-6">
            <div class="flex flex-row gap-20">
              <div class="flex flex-col gap-8">
                <div class="flex flex-col">
                  <h6>Customer Code</h6>
                  <span>
                    {{
                      responseData.invoice_exchange.client_code
                        ? responseData.invoice_exchange.client_code
                        : ""
                    }}</span
                  >
                </div>
                <div class="flex flex-col">
                  <h6 class="text-bold">Customer Name</h6>
                  <span>
                    {{
                      responseData.invoice_exchange.client_name
                        ? responseData.invoice_exchange.client_name
                        : ""
                    }}</span
                  >
                </div>
              </div>

              <div class="flex flex-col gap-8">
                <div class="flex flex-col">
                  <h6>Ship To Code</h6>
                  <span>
                    {{
                      responseData.customer_address.code
                        ? responseData.customer_address.code
                        : "-"
                    }}</span
                  >
                </div>
                <div class="flex flex-col">
                  <h6 class="text-bold">Ship To Name</h6>
                  <span> {{ responseData.customer_address.contact_name }}</span>
                </div>
              </div>

              <div class="flex flex-col gap-8">
                <div class="flex flex-col">
                  <h6 class="text-bold">Ship To Address</h6>
                  <span>
                    {{ responseData.customer_address.address }},
                    {{ responseData.customer_address.sub_district }}, <br />
                    {{ responseData.customer_address.sub_district }},
                    {{ responseData.customer_address.city }}
                  </span>
                </div>
              </div>
            </div>

            <div
              class="flex flex-col gap-3 w-1/2 mt-6"
              style="min-width: 300px"
            >
              <h6>Schedule</h6>
              <schedule-display
                :schedules="responseData.customer_invoice_exchange.schedules"
              />
            </div>

            <div class="flex flex-col gap-3 w-1/2" style="min-width: 300px">
              <h6>Nomor Tukar Faktur</h6>
              <vs-input
                v-model="exchangeModels.invoice_exchange_number"
                class="w-full"
              />
            </div>

            <div class="flex flex-col gap-3 w-1/2" style="min-width: 300px">
              <h6>Ship to Name</h6>
              <vs-input
                v-model="exchangeModels.distribution_channel"
                class="w-full"
              />
            </div>

            <!-- <div class="flex flex-col gap-3 w-1/2" style="min-width: 300px">
              <h6>Reference Code</h6>
              <vs-input v-model="exchangeModels.ref_code" class="w-full" />
            </div> -->

            <div class="flex flex-col gap-4 w-1/2" style="min-width: 300px">
              <h6>Tanggal Tukar Faktur</h6>
              <datepicker
                class="w-full"
                v-validate="'required'"
                :inline="false"
                name="Date Reversal"
                v-model="exchangeModels.date"
                placeholder="-"
              ></datepicker>
            </div>
            <div class="flex flex-col gap-3 w-1/2" style="min-width: 300px">
              <h6>Attachments</h6>
              <div class="flex justify-between items-center gap-3">
                <vs-input
                  type="file"
                  class="w-full"
                  v-model="file_name"
                  @change="onFileChange($event)"
                  multiple
                />
                <vs-button
                  color="danger"
                  icon-pack="feather"
                  icon="icon-x"
                  @click="resetFile"
                ></vs-button>
              </div>
            </div>
            <div
              class="flex flex-col gap-3 w-1/2"
              style="min-width: 300px"
              v-if="exchangeModels.file.length > 0"
            >
              <h6>Attachment List</h6>
              <div
                class="flex flex-col gap-6"
                v-if="exchangeModels.file.length > 0"
              >
                <div
                  class="flex gap-3 items-center"
                  v-for="(file, index) in exchangeModels.file"
                  :key="index"
                >
                  <span>{{ file.file_name }}</span>
                  <vx-tooltip text="Remove">
                    <vs-button
                      color="danger"
                      @click="deleteAttachment(index)"
                      icon-pack="feather"
                      icon="icon-trash"
                    />
                  </vx-tooltip>
                  <vx-tooltip text="Download">
                    <vs-button
                      color="success"
                      @click="downloadFileAwsS3(file.file_path)"
                      icon-pack="feather"
                      icon="icon-download"
                    />
                  </vx-tooltip>
                </div>
              </div>
              <div v-else><span class="text-grey">No attachment</span></div>
            </div>
          </div>

          <!-- <vs-collapse :key="collapseKey">
            <vs-collapse-item :open="true"> -->
          <div class="flex flex-col gap-3 items-end w-full mt-6">
            <div class="w-full" style="max-height: 300px; overflow: auto">
              <vs-table :data="this.invoicesDisplay">
                <template slot="thead">
                  <vs-th></vs-th>
                  <vs-th> Invoice </vs-th>
                  <vs-th> Customer GR Number </vs-th>
                  <vs-th> Supplier Code </vs-th>
                </template>

                <template>
                  <vs-tr :key="i" v-for="(invoice, i) in this.invoicesDisplay">
                    <vs-td>
                      <div class="flex justify-center">
                        <vx-tooltip text="Remove" style="width: fit-content">
                          <vs-button
                            color="danger"
                            type="line"
                            icon-pack="feather"
                            icon="icon-trash"
                            @click="handleOpenPrompt(invoice, i)"
                          />
                        </vx-tooltip>
                      </div>
                    </vs-td>
                    <vs-td>
                      Code : {{ invoice.invoice_code }}
                      <br />
                      Faktur : {{ invoice.faktur_number }}
                      <br />
                      Billing Date : {{ dateFormat(invoice.date) }}
                      <br />
                      Due Date : {{ dateFormat(invoice.due_date) }}
                      <br />
                      Posting Date : {{ dateFormat(invoice.posting_date) }}
                      <br />
                      Total : {{ priceFormat(invoice.total) }}
                    </vs-td>

                    <vs-td>
                      <vs-input
                        v-model="exchangeModels.invoices[i].gr_number"
                        class="w-full"
                      />
                    </vs-td>

                    <vs-td>
                      <vs-input
                        v-model="exchangeModels.invoices[i].supplier_code"
                        class="w-full"
                      />
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
            <vs-divider />
            <div v-if="isInvoiceEdit">
              <vs-button @click="addInvoice">Add Invoice</vs-button>
            </div>
            <div
              class="w-full mt-3 px-3"
              v-if="isInvoiceEdit"
              style="max-height: 600px; overflow: auto"
            >
              <data-table
                :responseData="invoiceData"
                :propsParams="params"
                :header="header"
                @reloadDataFromChild="reloadData"
              >
                <template slot="thead">
                  <th width="7%">
                    <vs-checkbox v-model="checkedAll">Select All</vs-checkbox>
                  </th>
                </template>
                <template slot="tbody">
                  <vs-tr
                    :key="index"
                    v-for="(record, index) in invoiceData.invoices"
                  >
                    <vs-td
                      ><vs-checkbox
                        v-on:click="check(record.invoice.ID, index)"
                        :checked="checked.includes(record.invoice.ID)"
                      ></vs-checkbox>
                    </vs-td>
                    <vs-td>
                      Code : {{ record.invoice.code }}
                      <br />
                      Faktur : {{ record.invoice.faktur_number }}
                      <br />
                      Invoice Reference Code :
                      {{ record.invoice.invoice_reference_code }}
                      <br />
                      Date : {{ dateFormat(record.invoice.date) }}
                      <br />
                      Due Date : {{ dateFormat(record.invoice.due_date) }}
                      <br />
                      <!-- Posting Date : {{ dateFormat(invoice.PostingDate) }}
                              <br />  -->
                      Created Date :
                      {{ dateFormat(record.invoice.CreatedAt) }}
                    </vs-td>
                    <vs-td>
                      Name : {{ record.invoice.client_name }}
                      <br />
                      Code : {{ record.invoice.client_code }}
                      <br />
                      Territory :
                      {{ getTerritoryName(record.invoice.territory_id) }}

                      <br />
                    </vs-td>
                    <vs-td>
                      Name : {{ record.customer_address.contact_name }}
                      <br />
                      Code : {{ record.customer_address.code }}
                      <br />
                      Address :
                      {{
                        `${record.customer_address.address}, ${record.customer_address.sub_district}, ${record.customer_address.sub_district}, ${record.customer_address.city}`
                      }}
                      <br />
                      Territory :
                      {{
                        getTerritoryName(record.customer_address.territory_id)
                      }}
                      <br />
                    </vs-td>
                    <vs-td>
                      Subtotal : {{ priceFormat(record.invoice.sub_total) }}
                      <br />
                      Discount : {{ priceFormat(record.invoice.discount) }}
                      <br />
                      Subtotal Charge :
                      {{ priceFormat(record.invoice.sub_total) }}
                      <br />
                      Tax : {{ priceFormat(record.invoice.tax_amount) }}
                      <br />
                      Total : {{ priceFormat(record.invoice.total) }}
                      <br />
                    </vs-td>
                  </vs-tr>
                </template>
              </data-table>
            </div>
          </div>
          <!-- </vs-collapse-item>
          </vs-collapse> -->
        </vx-card>
      </div>
      <vs-prompt
        @cancel="handleClosePrompt"
        @accept="removeInvoice"
        @close="handleClosePrompt"
        color="danger"
        :active.sync="activePrompt"
        title="Remove Invoice"
      >
        <div class="con-exemple-prompt">
          Are you sure want to remove invoice
          <span class="font-bold">{{
            selectedData.invoice_code ? selectedData.invoice_code : ""
          }}</span
          >?
        </div>
      </vs-prompt>
      <div class="flex gap-3">
        <vs-button color="danger" @click="navigateBack">Close</vs-button>
        <vs-button @click="updateData">Save</vs-button>
      </div>
    </div>
  </vx-card>
</template>
<script>
import moment from "moment";
import scheduleDisplay from "./schedule-display.vue";
import Datepicker from "vuejs-datepicker";
export default {
  components: {
    "schedule-display": scheduleDisplay,
    datepicker: Datepicker,
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "id",
      },
      header: [
        {
          text: "Invoice",
          value: "code",
          // width: '5%'
        },
        {
          text: "Sold To",
          sortable: false,
        },
        {
          text: "Ship To",
          sortable: false,
        },
        {
          text: "Amount",
          sortable: false,
        },
      ],
      responseData: {},
      invoiceData: {},
      file: null,
      isShow: false,
      invoice_exchange_id: 0,
      invoiceModels: [],
      isDialogShown: false,
      finalDatas: [],
      exchangeModels: {},
      files: [],
      file_name: "",
      selectedData: {},
      isInvoiceEdit: false,
      collapseKey: 0,
      activePrompt: false,
      checkedAll: false,
      checked: [],
      checkedInvoice: [],
      invoiceCodes: [],
      addedInvoice: [],
      invoicesDisplay: [],
      invoiceIndex: 0,
    };
  },
  mounted() {
    this.invoice_exchange_id = parseInt(this.$route.params.id);
    this.getInvoices();
  },
  methods: {
    dateFormat(value) {
      return moment(value).format("DD-MM-YYYY");
    },
    getTerritoryName(territoryID) {
      const territory = [...this.$store.state.operatingUnit.filter.territory];
      const territoryName = territory.find(
        (v) => v.TerritoryID === territoryID
      );
      return territoryName ? territoryName.TerritoryName : "";
    },
    reloadData(params) {
      this.params = params;
      this.invoiceCodes = this.invoicesDisplay.map((v) => v.invoice_code);
      this.$vs.loading();
      this.$http
        .get("api/v1/invoice/exchange-data-table", {
          params: {
            search: params.search,
            length: params.length,
            page: params.page,
            order: params.order,
            sort: params.sort,
            territory_ids: [this.responseData.invoice_exchange.territory_id],
            status_in: [1, 2, 3],
            customer_name: this.responseData.invoice_exchange.client_name,
            customer_code: this.responseData.invoice_exchange.client_code,
            //   !this.selectedCustomer.name || this.selectedCustomer.name == "All"
            //     ? undefined
            //     : this.selectedCustomer.name,
            // customer_code:
            //   !this.selectedCustomer.code || this.selectedCustomer.code == "All"
            //     ? undefined
            //     : this.selectedCustomer.code,
            // start_date: !this.selectedDate.startDate
            //   ? undefined
            //   : this.dateFormatApi(this.selectedDate.startDate),
            // end_date: !this.selectedDate.endDate
            //   ? undefined
            //   : this.dateFormatApi(this.selectedDate.endDate),
            codes: this.invoiceCodes,
            is_edit: true,
          },
        })
        .then((resp) => {
          console.log(resp);
          if (resp.code == 500) {
            this.$vs.loading.close();
          } else if (resp.code == 200) {
            this.$vs.loading.close();
            this.invoiceData = resp.data;
            this.checkedAll = false;
            this.checked = [];
            this.checkedInvoice = [];
            // this.collapseKey++;
            // this.checkedAll = false;
          } else {
            this.$vs.loading.close();
          }
        });
    },
    handleClosePrompt() {
      this.activePrompt = false;
      this.selectedData = {};
      this.invoiceIndex = 0;
    },

    showInvoice() {
      this.isInvoiceEdit = !this.isInvoiceEdit;
      this.collapseKey++;
    },

    handleOpenPrompt(data, index) {
      this.activePrompt = true;
      this.selectedData = data;
      this.invoiceIndex = index;
    },

    navigateBack() {
      this.$router.push({ name: "Invoice Exchange" });
    },

    validateInput() {
      let validated = false;

      if (
        this.invoicesDisplay.length === 0 ||
        this.exchangeModels.invoices.length === 0
      ) {
        this.$vs.notify({
          title: "Invoice exchange edit",
          text: "Please add at least one invoice",
          position: "top-right",
          color: "danger",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return validated;
      }

      if (this.exchangeModels.invoice_exchange_number.trim() === "") {
        this.$vs.notify({
          title: "Invoice exchange edit",
          text: "Nomor tukar faktur is required",
          position: "top-right",
          color: "danger",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return validated;
      }

      if (this.exchangeModels.distribution_channel.trim() === "") {
        this.$vs.notify({
          title: "Invoice exchange edit",
          text: "Ship to name is required",
          position: "top-right",
          color: "danger",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return validated;
      }

      // if (this.exchangeModels.ref_code.trim() === "") {
      //   this.$vs.notify({
      //     title: "Invoice exchange edit",
      //     text: "Please fill reference code",
      //     position: "top-right",
      //     color: "danger",
      //     iconPack: "feather",
      //     icon: "icon-x-circle",
      //   });
      //   return validated;
      // }

      if (this.exchangeModels.date === "") {
        this.$vs.notify({
          title: "Invoice exchange edit",
          text: "Tanggal tukar faktur is required",
          position: "top-right",
          color: "danger",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return validated;
      }

      for (let i = 0; i < this.exchangeModels.invoices.length; i++) {
        if (
          this.exchangeModels.invoices[i].gr_number.trim() === "" ||
          this.exchangeModels.invoices[i].supplier_code.trim() === ""
        ) {
          this.$vs.notify({
            title: "Invoice exchange creation",
            text: "Please fill all Customer GR Number and Supplier Code",
            position: "top-right",
            color: "danger",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return validated;
        }
      }

      // for (let i = 0; i < this.invoiceModels.length; i++) {
      //   const tempGR = this.invoiceModels[i].gr_number.trim();
      //   const tempSupplier = this.invoiceModels[i].supplier_code.trim();
      //   const tempGROld = this.responseData[i].gr_number.trim();
      //   const tempSupllierOld = this.responseData[i].supplier_code.trim();
      //   if (tempGR !== tempGROld || tempSupplier !== tempSupllierOld) {
      //     validated = true;
      //     break;
      //   }
      // }
      validated = true;
      return validated;
    },
    deleteAttachment(index) {
      ///invoice-exchange/attachment/:ID
      this.$vs.loading();
      this.$http
        .delete(
          `api/v1/invoice-exchange/attachment/${this.exchangeModels.file[index].id}`
        )
        .then((resp) => {
          console.log(resp);
          if (resp.code == 500) {
            this.$vs.loading.close();
          } else if (resp.code == 200) {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Invoice Exchange Update",
              text: "Attachment successfully deleted",
              iconPack: "feather",
              icon: "icon-check",
              color: "success",
              position: "top-right",
            });
            this.exchangeModels.file.splice(index, 1);
          } else {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Invoice Exchange Update",
              text: resp.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
              position: "top-right",
            });
          }
        })
        .catch((e) => {
          console.log(e);
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Invoice Exchange Update",
            text: e.meassge,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
          });
        });
    },
    getInvoices() {
      this.$vs.loading();
      this.$http
        .get(`api/v1/invoice-exchange/${this.invoice_exchange_id}`, {
          params: {
            is_canceled: false,
          },
        })
        .then((resp) => {
          console.log(resp);
          if (resp.code == 500) {
            this.$vs.loading.close();
          } else if (resp.code == 200) {
            this.$vs.loading.close();
            this.responseData = resp.data;

            this.invoicesDisplay = [...this.responseData.lines];
            console.log("disp", this.invoicesDisplay);
            this.exchangeModels = {
              id: this.invoice_exchange_id,
              date: this.responseData.invoice_exchange.date,
              ref_code: this.responseData.invoice_exchange.reference_code,
              invoice_exchange_number:
                this.responseData.invoice_exchange.invoice_exchange_number,
              distribution_channel:
                this.responseData.invoice_exchange.distribution_channel,
              invoices: this.responseData.lines.map((line) => ({
                id: line.ID,
                invoice_id: line.invoice_id,
                gr_number: line.gr_number,
                supplier_code: line.supplier_code,
              })),
              file: this.responseData.attachments.map((file) => ({
                id: file.ID,
                file_name: file.name_file,
                file_path: file.path_file,
              })),
            };
            this.isInvoiceEdit = true;
          } else {
            this.$vs.loading.close();
          }
        });
    },
    addInvoice() {
      //   {
      // "ID": 22,
      //       "CreatedAt": null,
      //       "DeletedAt": null,
      //       "invoice_exchange_id": 37,
      //       "invoice_id": 445649,
      //       "invoice_code": "D3502-900524000011",
      //       "faktur_number": "FAKTUR/D3502-900524000011",
      //       "gr_number": "GR-8989898989",
      //       "supplier_code": "SC-78787878",
      //       "sub_total": 55586,
      //       "tax_rate": 11,
      //       "tax_amount": 6114.46,
      //       "posting_date": "2024-05-23T00:00:00Z",
      //       "total": 61701.46,
      //       "discount": 0,
      //       "date": "2024-05-23T00:00:00Z",
      //       "due_date": "2024-05-23T00:00:00Z",
      //       "invoice_reference_code": ""
      //  }
      if (
        this.checkedInvoice.length === 0 ||
        this.checkedInvoice.length === 0
      ) {
        this.$vs.notify({
          title: "Invoice Exchange Update",
          text: "Please select at least one invoice",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
        });
        return;
      }

      for (let i = 0; i < this.checkedInvoice.length; i++) {
        const model = {
          gr_number: "",
          supplier_code: "",
        };

        console.log(
          "inv ID",
          this.checkedInvoice[i].invoice.ID,
          this.responseData.lines
        );
        const inv = this.responseData.lines.find(
          (v) => v.invoice_id === this.checkedInvoice[i].invoice.ID
        );

        if (!inv) {
          model.invoice_id = this.checkedInvoice[i].invoice.ID;
          model.id = 0;
        } else {
          model.invoice_id = inv.invoice_id;
          model.id = inv.ID;
          model.gr_number = inv.gr_number;
          model.supplier_code = inv.supplier_code;
        }
        const temp = {
          ID: 0,
          invoiceID: this.checkedInvoice[i].invoice.ID,
          invoice_code: this.checkedInvoice[i].invoice.code,
          faktur_number: this.checkedInvoice[i].invoice.faktur_number,
          gr_number: "",
          supplier_code: "",
          sub_total: this.checkedInvoice[i].invoice.sub_total,
          tax_rate: this.checkedInvoice[i].invoice.tax_rate,
          tax_amount: this.checkedInvoice[i].invoice.tax_amount,
          posting_date: this.checkedInvoice[i].invoice.posting_date,
          total: this.checkedInvoice[i].invoice.total,
          discount: this.checkedInvoice[i].invoice.discount,
          date: this.checkedInvoice[i].invoice.date,
          due_date: this.checkedInvoice[i].invoice.due_date,
        };
        this.exchangeModels.invoices.push(model);
        this.invoicesDisplay.push(temp);
        console.log("exch", this.exchangeModels.invoices);
      }

      this.reloadData(this.params);
    },

    removeInvoice() {
      this.invoicesDisplay.splice(this.invoiceIndex, 1);
      this.exchangeModels.invoices.splice(this.invoiceIndex, 1);
      this.selectedData = {};
      this.invoiceIndex = 0;
      this.reloadData(this.params);
    },
    openDialog() {
      for (let i = 0; i < this.invoiceModels.length; i++) {
        const tempGR = this.invoiceModels[i].gr_number.trim();
        const tempSupplier = this.invoiceModels[i].supplier_code.trim();
        const tempGROld = this.responseData[i].gr_number.trim();
        const tempSupllierOld = this.responseData[i].supplier_code.trim();
        if (tempGR !== tempGROld || tempSupplier !== tempSupllierOld) {
          this.finalDatas.push({
            id: this.responseData[i].ID,
            invoice_id: this.responseData[i].ID,
            gr_number: tempGR,
            supplier_code: tempSupplier,
          });
        }
      }

      console.log(this.finalDatas);

      if (this.finalDatas.length === 0) {
        this.$vs.notify({
          title: "Invoice Exchange Update",
          text: "No data to update",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
        });
        return;
      }

      if (
        this.finalDatas.some(
          (data) => data.gr_number === "" || data.supplier_code === ""
        )
      ) {
        this.$vs.notify({
          title: "Invoice Exchange Update",
          text: "Please fill all the fields",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
        });
        this.resetFinalDatas();
        return;
      }
      this.isDialogShown = true;
    },

    updateData() {
      if (!this.validateInput()) {
        return;
      }
      this.$vs.loading();

      const data = {
        id: this.exchangeModels.id,
        reference_code: this.exchangeModels.ref_code,
        invoice_exchange_number: this.exchangeModels.invoice_exchange_number,
        distribution_channel: this.exchangeModels.distribution_channel,
        date: moment(this.exchangeModels.date).format("YYYY-MM-DD"),
        // lines: ,
      };

      let lines = this.exchangeModels.invoices.map((invoice) => ({
        id: invoice.id,
        invoice_id: invoice.invoice_id,
        gr_number: invoice.gr_number,
        supplier_code: invoice.supplier_code,
      }));

      this.responseData.lines.forEach((element) => {
        const index = lines.findIndex(
          (v) => v.invoice_id === element.invoice_id
        );
        if (index === -1) {
          lines.push({
            id: element.ID,
            invoice_id: element.invoice_id,
            gr_number: element.gr_number,
            supplier_code: element.supplier_code,
            is_remove: true,
          });
        }
      });
      data.lines = lines;

      console.log("lines", data);

      const form = new FormData();

      for (let i = 0; i < this.files.length; i++) {
        form.append("files", this.files[i]);
      }

      form.append("data", JSON.stringify(data));

      this.$http
        .put("api/v1/invoice-exchange", form)
        .then((resp) => {
          console.log(resp);
          if (resp.code == 500) {
            this.$vs.loading.close();
          } else if (resp.code == 200) {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Invoice Exchange Update",
              text: "Invoice exchange successfully updated",
              iconPack: "feather",
              icon: "icon-check",
              color: "success",
              position: "top-right",
            });
            this.$router.push({ name: "Invoice Exchange" });
          } else {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Invoice Exchange Update",
              text: resp.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
              position: "top-right",
            });
          }
        })
        .catch((e) => {
          console.log(e);
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Invoice Exchange Update",
            text: e.meassge,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
          });
        });
    },
    resetFinalDatas() {
      this.finalDatas = [];
    },
    onFileChange(event) {
      this.files = event.target.files;
    },
    resetFile() {
      this.file_name = "";
      this.files = [];
    },
    getOneInvoice(invoiceID) {
      // /invoice/exchange/:ID
      this.$vs.loading();
      this.$http
        .get(`api/v1/invoice/exchange/${invoiceID}`)
        .then((resp) => {
          console.log(resp);
          if (resp.code == 500) {
            this.$vs.loading.close();
          } else if (resp.code == 200) {
            this.$vs.loading.close();
            const invoice = resp.data;
            console.log(invoice);
            // this.exchangeModels = {
            //   id: this.invoice_exchange_id,
            //   date: this.responseData.invoice_exchange.date,
            //   ref_code: this.responseData.invoice_exchange.reference_code,
            //   invoice_exchange_number:
            //     this.responseData.invoice_exchange.invoice_exchange_number,
            //   distribution_channel:
            //     this.responseData.invoice_exchange.distribution_channel,
            //   invoices: this.responseData.lines.map((line) => ({
            //     id: line.ID,
            //     gr_number: line.gr_number,
            //     supplier_code: line.supplier_code,
            //   })),
            //   file: this.responseData.attachments.map((file) => ({
            //     id: file.ID,
            //     file_name: file.name_file,
            //     file_path: file.path_file,
            //   })),
            // };
          } else {
            this.$vs.loading.close();
          }
        })
        .catch((e) => {
          console.log(e);
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Invoice Exchange Update",
            text: e.meassge,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
          });
        });
    },
    check(val) {
      if (this.checked.includes(val)) {
        this.checked.splice(this.checked.indexOf(val), 1);
        this.checkedInvoice.splice(this.checkedInvoice.indexOf(val), 1);
        // this.clientCodes.splice(this.clientCodes.indexOf(val), 1);
      } else {
        this.checked.push(val);
        this.checkedInvoice.push(
          this.invoiceData.invoices.find((v) => v.invoice.ID === val)
        );
        // this.clientCodes.push(
        //   this.responseData.invoices[index].invoice.client_code
        // );
      }
    },
  },
  watch: {
    checkedAll() {
      if (this.checkedAll) {
        this.checked = this.invoiceData.invoices.map((v) => v.invoice.ID);
        this.checkedInvoice = this.invoiceData.invoices.map((v) => v.invoice);
      } else {
        this.checked = [];
        this.checkedInvoice = [];
      }
      console.log(this.checked);
    },
    // invoiceData() {
    //   this.collapseKey++;
    // },
  },
};
</script>
