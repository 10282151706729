<template>
  <ul class="list-outside list-disc flex flex-col gap-3">
    <li v-for="(sch, index) in schedules" :key="index">
      <div class="flex gap-3">
        <span
          >Type : <span class="font-bold">{{ getType(sch.type) }}</span></span
        >
        <span v-if="sch.type.toLowerCase() === 'date'"
          >Date : <span class="font-bold">{{ sch.value }}</span>
        </span>
        <span v-if="sch.type.toLowerCase() === 'week'"
          >Week :
          <span class="font-bold">{{ `Week ${sch.week_of_month}` }}</span>
        </span>
        <span v-if="sch.type.toLowerCase() === 'week'"
          >Day : <span class="font-bold">{{ getWeekDay(sch.value - 1) }}</span>
        </span>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    schedules: Array,
  },
  data() {
    return {
      days: ["Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu", "Minggu"],
    };
  },
  methods: {
    getWeekDay(day) {
      return this.days[day];
    },
    getType(type) {
      return type.charAt(0).toUpperCase() + type.slice(1);
    },
  },
};
</script>
